import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kwerk',
  templateUrl: './kwerk.component.html',
  styleUrls: ['./kwerk.component.scss']
})
export class KwerkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
