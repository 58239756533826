import { Component } from '@angular/core';
import {slideInAnimation} from './route-animation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [ slideInAnimation ]
})
export class AppComponent {
  title = 'mit-portfolio';
  onActivate(event) {
    console.log('activeee');
    document.querySelector('body').scrollTo(0,0);
  }
}
